<template>
  <div class="companyProfile">
    <!-- 公司概况 -->
    <!-- <div class="cp-pic">
      <el-image
        style="width: 100%; height: 100%"
        :src="pageData.img"
        fit="cover"
      ></el-image>
    </div> -->
    <div class="cp-text" v-html="pageData.richText"></div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
import { mapState } from 'vuex'
export default {
  name: "",
  data() {
    return {
      pageData: "",
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("http://edu.siwonet.com:8099/queryTitleId", {
          params: {
            language: lang,
            titleId: this.nodeId[this.$route.query.titleId] || this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data.pageDatas[0]) {
              this.pageData = res.data.data.pageDatas[0];
            }
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
  computed: {
    ...mapState(['nodeId']),
  },
  filters: {},
};
</script>

<style lang="less" scoped>
.companyProfile {
  width: 100%;
  .cp-pic {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }
  .cp-text {
    font-size: 14px;
    color: #535354;
    // line-height: 36px;
  }
}
</style>
